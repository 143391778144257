<template>
  <li class="nav-item">
    <span>
      <strong>{{ appTitle }}</strong>
    </span>
    <span>-</span>
    <span>v{{ appVersion }} ({{ appDate }})</span>
    <span>&ensp;|&ensp;</span>
    <span>{{ appCopyright }}</span>
    <span>-</span>
    <a :href="appVendorUrl" target="_blank">{{ appVendorUrlTitle }}</a>
  </li>
</template>

<script>
export default {
  name: "Application",
  data() {
    return {
      appTitle: constant.APP_TITLE,
      appCopyright: constant.APP_COPYRIGHT,
      appDate: constant.APP_DATE,
      appVersion: constant.APP_VERSION,
      appVendorUrl: constant.APP_VENDOR_URL,
      appVendorUrlTitle: constant.APP_VENDOR_URL_TITLE,
    };
  },
};
</script>