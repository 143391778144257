<template>
  <li class="nav-item">
    <span class="mr-2">{{ $t("licenseExpirationTime") }}</span>
    <span class="badge badge-pill badge-danger" v-if="diffDays > 0">{{
      diffDays + " " + $t("day")
    }}</span>
    <span class="badge badge-pill badge-danger" v-if="diffDays <= 0"
      >Lisans süreniz bitti!</span
    >
  </li>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "License",
  data() {
    return {
      diffDays: null,
    };
  },
  computed: {
    ...mapState(["session"]),
  },
  methods: {
    getExpireDay() {
      const nowDate = new Date(Date.now());

      const expireDate = new Date(this.session.config["app.expired_at"]);

      const diffTime = expireDate - nowDate;

      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

      this.diffDays = diffDays;
    },
  },
  created() {
    this.getExpireDay();
  },
};
</script>