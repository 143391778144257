<template>
  <!-- Footer -->
  <footer class="footer font-14">
    <div class="container-fluid">
      <nav class="navbar navbar-expand-lg m-0 p-0">
        <div class="collapse navbar-collapse show">
          <ul class="navbar-nav">
            <Application />
            <li class="nav-item ml-3 mr-3 d-none d-lg-block">|</li>
            <License />
          </ul>
          <ul class="navbar-nav ml-md-auto">
            <Links />
          </ul>
        </div>
      </nav>
    </div>
  </footer>
  <!-- ./Footer -->
</template>

<script>
import Application from "./components/Application/Index";
import License from "./components/License/Index";
import Links from "./components/Links/Index";

export default {
  name: "WeAppFooter",
  components: {
    Application,
    License,
    Links,
  },
};
</script>

<style lang="scss">
.footer {
  font-size: 14px;
  flex-shrink: 0;
  width: 100%;
  height: auto;
  line-height: 60px;
  background-color: #ced6e0;
}
</style>