<template>
  <li class="nav-item">
    <router-link to="/documents" class="btn btn-link no-decoration">
      <i class="fas fa-file-alt"></i>
      {{ $t("documents") }}
    </router-link>
  </li>
</template>

<script>
export default {
  name: "Links",
};
</script>